<template>
  <SignUsersTable />
</template>
<script>
import SignUsersTable from "@/components/sign/users/SignUsersTable";
import { getVacationDates } from "@/helpers/services/utils";
import { newUsersTableHeigth } from "@/helpers/variables/tablesDefault";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";

export default {
  components: { SignUsersTable },
  data() {
    return {
      tipInfo: true,
      bannerImage: require("@/assets/images/mensagem_de_ferias.gif"),
    };
  },
  methods: {
    ...mapActions(["checkScopes"]),
    ...mapMutations([
      "setNewUsersTableHeigth",
      "setShowVacationMessageBanner",
      "setAcceptedDriveScopes",
    ]),

    dimissBanner() {
      this.setShowVacationMessageBanner(false);
      this.setNewUsersTableHeigth(newUsersTableHeigth);
    },
  },
  computed: {
    ...mapGetters([
      "contentWidth",
      "showVacationMessageBanner",
      "hasAcceptedDriveScopes",
    ]),

    showNewTipInfo: {
      get() {
        return this.tipInfo;
      },
      set(new_value) {
        this.tipInfo = new_value;
        localStorage.setItem("showNewTipInfo", this.tipInfo);
      },
    },
  },
  beforeMount() {
    if (!localStorage.getItem("showNewTipInfo")) {
      this.tipInfo = true;
      localStorage.setItem("showNewTipInfo", "true");
    } else {
      this.tipInfo = JSON.parse(localStorage.getItem("showNewTipInfo"));
    }

    const { vacationStart, vacationEnd } = getVacationDates();

    if (vacationStart && vacationEnd) {
      this.setNewUsersTableHeigth("calc(60vh - 220px)");
      this.setShowVacationMessageBanner(true);
    }

    if (!this.hasAcceptedDriveScopes) {
      const payload = {
        scopes: [INCREMENTAL_SCOPES.DRIVE],
      };

      this.checkScopes(payload)
        .then(() => {
          this.setAcceptedDriveScopes(true);
        })
        .catch(() => {
          this.setAcceptedDriveScopes(false);
        });
    }
  },
};
</script>
<style scoped>
.large-line-height {
  line-height: 25px !important;
}
</style>
